:root{
    --filter-height: 13vh;
}

#dashboard-container {
    width: 98%;
    margin: 0 auto
}

#scene-filter{
    height: var(--filter-height);
}

#scene-list{
    /* height: calc(100vh - (100vh - var(--content-container-height)) - var(--filter-height) - 50vh); */
    overflow-y: auto;
}

.scene-list-item{

    padding: 0.25rem 0.15rem;
    margin: 0.25rem 0;
    background-color: var(--neutral2);

    border-radius: 2px;

    cursor: pointer;


}

.scene-list-item:hover{
    color: var(--primaryAccent1);
    background-color: var(--neutral-2);
}

.scene-list-item-name{
    font-weight: bold;
}

.scene-list-item.selected{
    background-color: var(--primaryAccent0);
    color: var(--neutral-2);
}

.scene-stability-swatch{
    height: 2rem;
    padding: 0 0.25rem;
    color: var(--neutral0);

    border-radius: 4px;
    box-shadow: 1px 1px 3px var(--neutral0);


}

.dashboard-chart{
    height: 95%;
    width: 100%;
}


.dashboard-panel{
    padding: 0.5rem 1rem;

    box-sizing: border-box;

    text-align: left;
    color: var(--neutral9);

    background: linear-gradient(135deg, var(--neutral3), var(--neutral2));
    box-shadow: 1px 1px 5px var(--neutral-3);
    
    border-radius: 4px;
}

.dashboard-panel-header{
    margin: 0;  
    color: var(--primaryAccent1);

    text-shadow: 1px 1px 4px var(--primaryAccent-8);
}

.dashboard-panel-section{
    padding: 0.25rem 0;

    border-radius: 4px;
    background-color: var(--neutral5);
    box-shadow: 1px 1px 3px var(--neutral0) inset;
}




.map-image-selector{
    display: inline-block;
    margin: 0.5rem 0.25rem;
    padding: 0.15rem;

    box-shadow: 1px 1px 3px var(--neutral0);
    border-radius: 4px;

    cursor: pointer;
}

.map-image-selector.selected{
    background-color: var(--primaryAccent0);
    color: var(--neutral0);
}