
.image-annotation-manager-container{
    position: relative;
    overflow: auto;
}   

.annotation-svg{
    position: absolute;
    user-select: none;
}

.annotation-image-container, .annotation-image{
    position: absolute;
}

.annotation-handle,
.annotation-line,
.annotation-circle{
    cursor: pointer;
}

.annotation-class-select:active, .annotation-class-select:hover{
    outline: none;
}

.image-tile{
    padding: 0;
    margin: 0;
    display: inline;
}