#login-form{

    width: fit-content;
    margin: 0 auto;
    padding: 0 2rem 1rem;
    
    font-size: 2rem;
    text-align: left;

    color: var(--neutral-3);
    /* background-color: var(--neutral3); */
    background-image: linear-gradient(var(--neutral-1), var(--neutral1));
    background-size: 100% 100%;
    border-radius: 4px;

    box-shadow: 2px 2px 20px var(--neutral-3);

    animation-name: gradient-shift;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;

}

@keyframes gradient-shift {
    from{
        background-position: 0% 50%;
    }
    to{
        background-position: 100% 50%;
    }
}

.login-error-message:before{
    content: "Error: "
}

.login-error-message{
    color: var(--secondaryAccent0);
    text-shadow: 1px 1px 3px var(--neutral-2);
    font-style: italic;
    font-weight: 100;
    font-size: 1rem;
}