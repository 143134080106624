/* 
:::::::::::::::::::::::
:: INLINE BUTTON ::
:::::::::::::::::::::::
*/

.inline-button{
    padding: 0.5rem;
    
    font-weight: bold;
    text-transform: uppercase;
    
    background-color: rgb(0,0,0,0);

    border-radius: 2px;
    border: none;

    cursor: pointer;

    margin: 0.5rem;

}

.inline-button:hover{
    animation-name: inline-button-hover;
    animation-duration: 333ms;
    animation-fill-mode: forwards;
}

.inline-button:disabled{
    opacity: 0.33;
}

@keyframes inline-button-hover{
    from{
        box-shadow: 0 0 0 grey;
    }
    to{
        box-shadow: 2px 2px 5px grey;
    }
}