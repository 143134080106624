.map-popup-3d{
    position: fixed;
    z-index: 100000;

    border: 3px solid #616A6B;
    border-radius: 4px;
    
    box-shadow: 2px 2px 10px #616A6B;
    
    background-color: rgba(245, 245, 245, 0.5);
   
    cursor: auto;
    min-width: 4rem;
    min-height: 1rem;
    backdrop-filter: blur(3px);
}

.map-popup-inner-3d{
    padding: 0.25rem;
    display:flex;
    flex-flow: column;
    flex: 1 1 auto
}

.map-popup-close-btn-3d{
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    left: -0.25rem;

}

.map-popup-window-controls-3d{
    cursor: grab;
    text-align: left;
    border-bottom: 1px solid grey;
    margin: 0 0 0.25rem;
    min-height: 1rem;
}

.map-popup-3d .map-popup-resizer-3d { 
    width: 20px;
    height: 20px;
    position:absolute;
    right: -10px;
    bottom: -10px;
    cursor: se-resize;
}
