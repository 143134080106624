.satellite-spinner{
    display: inline-block;
    background-image: url('../../assets/icons/satellite.svg');
    background-size: cover;
}

.satellite-spinner.spin{
    animation: spin 2s infinite linear;
}

@keyframes spin {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}