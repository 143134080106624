.icon-button{
    cursor: pointer;
    text-align: center;
    padding: 0.25rem;
}

.icon-button img:hover{
    animation-name: hover-icon;
    animation-duration: 250ms;
    animation-fill-mode: forwards;
}

@keyframes hover-icon{
    from{
        transform: scale(1);
    }
    to{
        transform: scale(1.1);
    }
}

.icon-button-disabled{
    opacity: 0.5;
    cursor: not-allowed;
}