#map-coordinate-box-3d{
    padding: 0.5rem;

    z-index: 100001;
    position: fixed;
    display: block;

    background-color: rgba(245, 245, 245, 0.50);
    backdrop-filter: blur(2px);    

    border-radius: 2px;
    box-shadow: 2px 2px 5px grey;

    cursor: default;

    font-family: sans-serif;
}

#map-coordinate-box-3d table, #map-coordinate-box-3d td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 0.25rem;
}