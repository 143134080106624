.cesium-svgPath-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.cesium-button {
    display: inline-block;
    position: relative;
    background: #303336;
    border: 1px solid #444;
    color: #edffff;
    fill: #edffff;
    border-radius: 4px;
    padding: 5px 12px;
    margin: 2px 3px;
    cursor: pointer;
    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.cesium-button:focus {
    color: #fff;
    fill: #fff;
    border-color: #ea4;
    outline: 0
}

.cesium-button:hover {
    color: #fff;
    fill: #fff;
    background: #48b;
    border-color: #aef;
    box-shadow: 0 0 8px #fff
}

.cesium-button:active {
    color: #000;
    fill: #000;
    background: #adf;
    border-color: #fff;
    box-shadow: 0 0 8px #fff
}

.cesium-button-disabled, .cesium-button-disabled:active, .cesium-button-disabled:focus, .cesium-button-disabled:hover, .cesium-button:disabled {
    background: #303336;
    border-color: #444;
    color: #646464;
    fill: #646464;
    box-shadow: none;
    cursor: default
}

.cesium-button option {
    background-color: #000;
    color: #eee
}

.cesium-button option:disabled {
    color: #777
}

.cesium-button input, .cesium-button label {
    cursor: pointer
}

.cesium-button input {
    vertical-align: sub
}

.cesium-toolbar-button {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 14%;
    padding: 0;
    vertical-align: middle;
    z-index: 0
}

.cesium-performanceDisplay-defaultContainer {
    position: absolute;
    top: 50px;
    right: 10px;
    text-align: right
}

.cesium-performanceDisplay {
    background-color: rgba(40, 40, 40, .7);
    padding: 7px;
    border-radius: 5px;
    border: 1px solid #444;
    font: bold 12px sans-serif
}

.cesium-performanceDisplay-fps {
    color: #e52
}

.cesium-performanceDisplay-throttled {
    color: #a42
}

.cesium-performanceDisplay-ms {
    color: #de3
}

.cesium-animation-theme {
    visibility: hidden;
    display: block;
    position: absolute;
    z-index: -100
}

.cesium-animation-themeNormal {
    color: #222
}

.cesium-animation-themeHover {
    color: #4488b0
}

.cesium-animation-themeSelect {
    color: #242
}

.cesium-animation-themeDisabled {
    color: #333
}

.cesium-animation-themeKnob {
    color: #222
}

.cesium-animation-themePointer {
    color: #2e2
}

.cesium-animation-themeSwoosh {
    color: #8ac
}

.cesium-animation-themeSwooshHover {
    color: #aef
}

.cesium-animation-svgText {
    fill: #edffff;
    font-family: Sans-Serif;
    font-size: 15px;
    text-anchor: middle
}

.cesium-animation-blank {
    fill: #000;
    fill-opacity: .01;
    stroke: none
}

.cesium-animation-rectButton {
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.cesium-animation-rectButton .cesium-animation-buttonGlow {
    fill: #fff;
    stroke: none;
    display: none
}

.cesium-animation-rectButton:hover .cesium-animation-buttonGlow {
    display: block
}

.cesium-animation-rectButton .cesium-animation-buttonPath {
    fill: #edffff
}

.cesium-animation-rectButton .cesium-animation-buttonMain {
    stroke: #444;
    stroke-width: 1.2
}

.cesium-animation-rectButton:hover .cesium-animation-buttonMain {
    stroke: #aef
}

.cesium-animation-rectButton:active .cesium-animation-buttonMain {
    fill: #abd6ff
}

.cesium-animation-buttonDisabled {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.cesium-animation-buttonDisabled .cesium-animation-buttonMain {
    stroke: #555
}

.cesium-animation-buttonDisabled .cesium-animation-buttonPath {
    fill: #818181
}

.cesium-animation-buttonDisabled .cesium-animation-buttonGlow {
    display: none
}

.cesium-animation-buttonToggled .cesium-animation-buttonGlow {
    display: block;
    fill: #2e2
}

.cesium-animation-buttonToggled .cesium-animation-buttonMain {
    stroke: #2e2
}

.cesium-animation-buttonToggled:hover .cesium-animation-buttonGlow {
    fill: #fff
}

.cesium-animation-buttonToggled:hover .cesium-animation-buttonMain {
    stroke: #2e2
}

.cesium-animation-shuttleRingG {
    cursor: pointer
}

.cesium-animation-shuttleRingPointer {
    cursor: pointer
}

.cesium-animation-shuttleRingPausePointer {
    cursor: pointer
}

.cesium-animation-shuttleRingBack {
    fill: #181818;
    fill-opacity: .8;
    stroke: #333;
    stroke-width: 1.2
}

.cesium-animation-shuttleRingSwoosh line {
    stroke: #8ac;
    stroke-width: 3;
    stroke-opacity: .2;
    stroke-linecap: round
}

.cesium-animation-knobOuter {
    cursor: pointer;
    stroke: #444;
    stroke-width: 1.2
}

.cesium-animation-knobInner {
    cursor: pointer
}

.cesium-baseLayerPicker-selected {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none
}

.cesium-baseLayerPicker-dropDown {
    display: block;
    position: absolute;
    box-sizing: content-box;
    top: auto;
    right: 0;
    width: 320px;
    max-height: 500px;
    margin-top: 5px;
    background-color: rgba(38, 38, 38, .75);
    border: 1px solid #444;
    padding: 6px;
    overflow: auto;
    border-radius: 10px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: translate(0, -20%);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .2s, opacity .2s ease-in, transform .2s ease-in
}

.cesium-baseLayerPicker-dropDown-visible {
    transform: translate(0, 0);
    visibility: visible;
    opacity: 1;
    transition: opacity .2s ease-out, transform .2s ease-out
}

.cesium-baseLayerPicker-sectionTitle {
    display: block;
    font-family: sans-serif;
    font-size: 16pt;
    text-align: left;
    color: #edffff;
    margin-bottom: 4px
}

.cesium-baseLayerPicker-choices {
    margin-bottom: 5px
}

.cesium-baseLayerPicker-categoryTitle {
    color: #edffff;
    font-size: 11pt
}

.cesium-baseLayerPicker-choices {
    display: block;
    border: 1px solid #888;
    border-radius: 5px;
    padding: 5px 0
}

.cesium-baseLayerPicker-item {
    display: inline-block;
    vertical-align: top;
    margin: 2px 5px;
    width: 64px;
    text-align: center;
    cursor: pointer
}

.cesium-baseLayerPicker-itemLabel {
    display: block;
    font-family: sans-serif;
    font-size: 8pt;
    text-align: center;
    vertical-align: middle;
    color: #edffff;
    cursor: pointer;
    word-wrap: break-word
}

.cesium-baseLayerPicker-item:focus .cesium-baseLayerPicker-itemLabel, .cesium-baseLayerPicker-item:hover .cesium-baseLayerPicker-itemLabel {
    text-decoration: underline
}

.cesium-baseLayerPicker-itemIcon {
    display: inline-block;
    position: relative;
    width: inherit;
    height: auto;
    background-size: 100% 100%;
    border: solid 1px #444;
    border-radius: 9px;
    color: #edffff;
    margin: 0;
    padding: 0;
    cursor: pointer;
    box-sizing: border-box
}

.cesium-baseLayerPicker-item:hover .cesium-baseLayerPicker-itemIcon {
    border-color: #fff;
    box-shadow: 0 0 8px #fff, 0 0 8px #fff
}

.cesium-baseLayerPicker-selectedItem .cesium-baseLayerPicker-itemLabel {
    color: #bdecf8
}

.cesium-baseLayerPicker-selectedItem .cesium-baseLayerPicker-itemIcon {
    border: double 4px #bdecf8
}

.cesium-widget {
    position: relative
}

.cesium-widget, .cesium-widget canvas {
    width: 100%;
    height: 100%;
    touch-action: none
}

.cesium-widget-credits {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    font-size: 10px;
    text-shadow: 0 0 2px #000;
    padding-right: 5px
}

.cesium-widget-credits a, .cesium-widget-credits a:visited {
    color: #fff
}

.cesium-widget-errorPanel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background: rgba(0, 0, 0, .7);
    z-index: 99999
}

.cesium-widget-errorPanel:before {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    content: ""
}

.cesium-widget-errorPanel-content {
    width: 75%;
    max-width: 500px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #510c00;
    border-radius: 7px;
    background-color: #f0d9d5;
    font-size: 14px;
    color: #510c00
}

.cesium-widget-errorPanel-content.expanded {
    max-width: 75%
}

.cesium-widget-errorPanel-header {
    font-size: 18px;
    font-family: "Open Sans", Verdana, Geneva, sans-serif;
    background: #d69d93;
    border-bottom: 2px solid #510c00;
    padding-bottom: 10px;
    border-radius: 3px 3px 0 0;
    padding: 15px
}

.cesium-widget-errorPanel-scroll {
    overflow: auto;
    font-family: "Open Sans", Verdana, Geneva, sans-serif;
    white-space: pre-wrap;
    padding: 0 15px;
    margin: 10px 0 20px 0
}

.cesium-widget-errorPanel-buttonPanel {
    padding: 0 15px;
    margin: 10px 0 20px 0;
    text-align: right
}

.cesium-widget-errorPanel-buttonPanel button {
    border-color: #510c00;
    background: #d69d93;
    color: #202020;
    margin: 0
}

.cesium-widget-errorPanel-buttonPanel button:focus {
    border-color: #510c00;
    background: #f0d9d5;
    color: #510c00
}

.cesium-widget-errorPanel-buttonPanel button:hover {
    border-color: #510c00;
    background: #f0d9d5;
    color: #510c00
}

.cesium-widget-errorPanel-buttonPanel button:active {
    border-color: #510c00;
    background: #b17b72;
    color: #510c00
}

.cesium-widget-errorPanel-more-details {
    text-decoration: underline;
    cursor: pointer
}

.cesium-widget-errorPanel-more-details:hover {
    color: #2b0700
}

.cesium-cesiumInspector {
    border-radius: 5px;
    transition: width ease-in-out .25s;
    background: rgba(48, 51, 54, .8);
    border: 1px solid #444;
    color: #edffff;
    display: inline-block;
    position: relative;
    padding: 4px 12px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden
}

.cesium-cesiumInspector-button {
    text-align: center;
    font-size: 11pt
}

.cesium-cesiumInspector-visible .cesium-cesiumInspector-button {
    border-bottom: 1px solid #aaa;
    padding-bottom: 3px
}

.cesium-cesiumInspector input:enabled, .cesium-cesiumInspector-button {
    cursor: pointer
}

.cesium-cesiumInspector-visible {
    width: 185px;
    height: auto
}

.cesium-cesiumInspector-hidden {
    width: 122px;
    height: 17px
}

.cesium-cesiumInspector-sectionContent {
    max-height: 500px
}

.cesium-cesiumInspector-section-collapsed .cesium-cesiumInspector-sectionContent {
    max-height: 0;
    padding: 0!important;
    overflow: hidden
}

.cesium-cesiumInspector-dropDown {
    margin: 5px 0;
    font-family: sans-serif;
    font-size: 10pt;
    width: 185px
}

.cesium-cesiumInspector-frustumStatistics {
    padding-left: 10px;
    padding: 5px;
    background-color: rgba(80, 80, 80, .75)
}

.cesium-cesiumInspector-pickButton {
    background-color: rgba(0, 0, 0, .3);
    border: 1px solid #444;
    color: #edffff;
    border-radius: 5px;
    padding: 3px 7px;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 auto
}

.cesium-cesiumInspector-pickButton:focus {
    outline: 0
}

.cesium-cesiumInspector-pickButton:active, .cesium-cesiumInspector-pickButtonHighlight {
    color: #000;
    background: #adf;
    border-color: #fff;
    box-shadow: 0 0 8px #fff
}

.cesium-cesiumInspector-center {
    text-align: center
}

.cesium-cesiumInspector-sectionHeader {
    font-weight: 700;
    font-size: 10pt;
    margin: 0;
    cursor: pointer
}

.cesium-cesiumInspector-pickSection {
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 3px;
    margin-bottom: 5px
}

.cesium-cesiumInspector-sectionContent {
    margin-bottom: 10px;
    transition: max-height .25s
}

.cesium-cesiumInspector-tileText {
    padding-bottom: 10px;
    border-bottom: 1px solid #aaa
}

.cesium-cesiumInspector-relativeText {
    padding-top: 10px
}

.cesium-cesiumInspector-sectionHeader::before {
    margin-right: 5px;
    content: "-";
    width: 1ch;
    display: inline-block
}

.cesium-cesiumInspector-section-collapsed .cesium-cesiumInspector-sectionHeader::before {
    content: "+"
}

ul.cesium-cesiumInspector-statistics {
    margin: 0;
    padding-top: 3px;
    padding-bottom: 3px
}

ul.cesium-cesiumInspector-statistics+ul.cesium-cesiumInspector-statistics {
    border-top: 1px solid #aaa
}

.cesium-cesiumInspector-slider {
    margin-top: 5px
}

.cesium-cesiumInspector-slider input[type=number] {
    text-align: left;
    background-color: #222;
    outline: 0;
    border: 1px solid #444;
    color: #edffff;
    width: 100px;
    border-radius: 3px;
    padding: 1px;
    margin-left: 10px;
    cursor: auto
}

.cesium-cesiumInspector-slider input[type=number]::-webkit-inner-spin-button, .cesium-cesiumInspector-slider input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.cesium-cesiumInspector-slider input[type=range] {
    margin-left: 5px;
    vertical-align: middle
}

.cesium-cesiumInspector-hide .cesium-cesiumInspector-styleEditor {
    display: none
}

.cesium-cesiumInspector-styleEditor {
    padding: 10px;
    border-radius: 5px;
    background: rgba(48, 51, 54, .8);
    border: 1px solid #444
}

.cesium-cesiumInspector-styleEditor textarea {
    width: 100%;
    height: 300px;
    background: 0 0;
    color: #edffff;
    border: none;
    padding: 0;
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: auto
}

.cesium-3DTilesInspector {
    width: 300px;
    pointer-events: all
}

.cesium-3DTilesInspector-statistics {
    font-size: 11px
}

.cesium-3DTilesInspector div, .cesium-3DTilesInspector input[type=range] {
    width: 100%;
    box-sizing: border-box
}

.cesium-cesiumInspector-error {
    color: #ff9e9e;
    overflow: auto
}

.cesium-3DTilesInspector .cesium-cesiumInspector-section {
    margin-top: 3px
}

.cesium-3DTilesInspector .cesium-cesiumInspector-sectionHeader+.cesium-cesiumInspector-show {
    border-top: 1px solid #fff
}

input.cesium-cesiumInspector-url {
    overflow: hidden;
    white-space: nowrap;
    overflow-x: scroll;
    background-color: transparent;
    color: #fff;
    outline: 0;
    border: none;
    height: 1em;
    width: 100%
}

.cesium-cesiumInspector .field-group {
    display: table
}

.cesium-cesiumInspector .field-group>label {
    display: table-cell;
    font-weight: 700
}

.cesium-cesiumInspector .field-group>.field {
    display: table-cell;
    width: 100%
}

.cesium-button.cesium-fullscreenButton {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0
}

.cesium-button.cesium-vrButton {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0
}

.cesium-viewer-geocoderContainer .cesium-geocoder-input {
    border: solid 1px #444;
    background-color: rgba(40, 40, 40, .7);
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 32px;
    margin: 0;
    padding: 0 32px 0 0;
    border-radius: 0;
    box-sizing: border-box;
    transition: width ease-in-out .25s, background-color .2s ease-in-out;
    -webkit-appearance: none
}

.cesium-viewer-geocoderContainer:hover .cesium-geocoder-input {
    border-color: #aef;
    box-shadow: 0 0 8px #fff
}

.cesium-viewer-geocoderContainer .cesium-geocoder-input:focus {
    border-color: #ea4;
    background-color: rgba(15, 15, 15, .9);
    box-shadow: none;
    outline: 0
}

.cesium-viewer-geocoderContainer .cesium-geocoder-input-wide, .cesium-viewer-geocoderContainer .cesium-geocoder-input:focus, .cesium-viewer-geocoderContainer:hover .cesium-geocoder-input {
    padding-left: 4px;
    width: 250px
}

.cesium-viewer-geocoderContainer .search-results {
    position: absolute;
    background-color: #000;
    color: #eee;
    overflow-y: auto;
    opacity: .8;
    width: 100%
}

.cesium-viewer-geocoderContainer .search-results ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.cesium-viewer-geocoderContainer .search-results ul li {
    font-size: 14px;
    padding: 3px 10px
}

.cesium-viewer-geocoderContainer .search-results ul li:hover {
    cursor: pointer
}

.cesium-viewer-geocoderContainer .search-results ul li.active {
    background: #48b
}

.cesium-geocoder-searchButton {
    background-color: #303336;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    width: 32px;
    top: 1px;
    right: 1px;
    height: 30px;
    vertical-align: middle;
    fill: #edffff
}

.cesium-geocoder-searchButton:hover {
    background-color: #48b
}

.cesium-infoBox {
    display: block;
    position: absolute;
    top: 50px;
    right: 0;
    width: 40%;
    max-width: 480px;
    background: rgba(38, 38, 38, .95);
    color: #edffff;
    border: 1px solid #444;
    border-right: none;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    box-shadow: 0 0 10px 1px #000;
    transform: translate(100%, 0);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .2s, opacity .2s ease-in, transform .2s ease-in
}

.cesium-infoBox-visible {
    transform: translate(0, 0);
    visibility: visible;
    opacity: 1;
    transition: opacity .2s ease-out, transform .2s ease-out
}

.cesium-infoBox-title {
    display: block;
    height: 20px;
    padding: 5px 30px 5px 25px;
    background: #545454;
    border-top-left-radius: 7px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: content-box
}

.cesium-infoBox-bodyless .cesium-infoBox-title {
    border-bottom-left-radius: 7px
}

button.cesium-infoBox-camera {
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 22px;
    height: 22px;
    background: 0 0;
    border-color: transparent;
    border-radius: 3px;
    padding: 0 5px;
    margin: 0
}

button.cesium-infoBox-close {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    background: 0 0;
    border: none;
    border-radius: 2px;
    font-weight: 700;
    font-size: 16px;
    padding: 0 5px;
    margin: 0;
    color: #edffff
}

button.cesium-infoBox-close:focus {
    background: rgba(238, 136, 0, .44);
    outline: 0
}

button.cesium-infoBox-close:hover {
    background: #888;
    color: #000
}

button.cesium-infoBox-close:active {
    background: #a00;
    color: #000
}

.cesium-infoBox-bodyless .cesium-infoBox-iframe {
    display: none
}

.cesium-infoBox-iframe {
    border: none;
    width: 100%;
    width: calc(100% - 2px)
}

span.cesium-sceneModePicker-wrapper {
    display: inline-block;
    position: relative;
    margin: 0 3px
}

.cesium-sceneModePicker-visible {
    visibility: visible;
    opacity: 1;
    transition: opacity .25s linear
}

.cesium-sceneModePicker-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .25s, opacity .25s linear
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-none {
    display: none
}

.cesium-sceneModePicker-slide-svg {
    transition: left 2s;
    top: 0;
    left: 0
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-dropDown-icon {
    box-sizing: border-box;
    padding: 0;
    margin: 3px 0
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button2D, .cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button3D, .cesium-sceneModePicker-wrapper .cesium-sceneModePicker-buttonColumbusView {
    margin: 0 0 3px 0
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button3D .cesium-sceneModePicker-icon2D {
    left: 100%
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button3D .cesium-sceneModePicker-iconColumbusView {
    left: 200%
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-buttonColumbusView .cesium-sceneModePicker-icon3D {
    left: -200%
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-buttonColumbusView .cesium-sceneModePicker-icon2D {
    left: -100%
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button2D .cesium-sceneModePicker-icon3D {
    left: -100%
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button2D .cesium-sceneModePicker-iconColumbusView {
    left: 100%
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-selected {
    border-color: #2e2;
    box-shadow: 0 0 8px #fff, 0 0 8px #fff
}

span.cesium-projectionPicker-wrapper {
    display: inline-block;
    position: relative;
    margin: 0 3px
}

.cesium-projectionPicker-visible {
    visibility: visible;
    opacity: 1;
    transition: opacity .25s linear
}

.cesium-projectionPicker-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .25s, opacity .25s linear
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-none {
    display: none
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-dropDown-icon {
    box-sizing: border-box;
    padding: 0;
    margin: 3px 0
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-buttonOrthographic, .cesium-projectionPicker-wrapper .cesium-projectionPicker-buttonPerspective {
    margin: 0 0 3px 0
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-buttonPerspective .cesium-projectionPicker-iconOrthographic {
    left: 100%
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-buttonOrthographic .cesium-projectionPicker-iconPerspective {
    left: -100%
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-selected {
    border-color: #2e2;
    box-shadow: 0 0 8px #fff, 0 0 8px #fff
}

.cesium-performance-watchdog-message-area {
    position: relative;
    background-color: #ff0;
    color: #000;
    padding: 10px
}

.cesium-performance-watchdog-message {
    margin-right: 30px
}

.cesium-performance-watchdog-message-dismiss {
    position: absolute;
    right: 0;
    margin: 0 10px 0 0
}

.cesium-navigationHelpButton-wrapper {
    position: relative;
    display: inline-block
}

.cesium-navigation-help {
    visibility: hidden;
    position: absolute;
    top: 38px;
    right: 2px;
    width: 250px;
    border-radius: 10px;
    transform: scale(.01);
    transform-origin: 234px -10px;
    transition: visibility 0s .25s, transform .25s ease-in
}

.cesium-navigation-help-visible {
    visibility: visible;
    transform: scale(1);
    transition: transform .25s ease-out
}

.cesium-navigation-help-instructions {
    border: 1px solid #444;
    background-color: rgba(38, 38, 38, .75);
    padding-bottom: 5px;
    border-radius: 0 0 10px 10px
}

.cesium-click-navigation-help {
    display: none
}

.cesium-touch-navigation-help {
    display: none;
    padding-top: 5px
}

.cesium-click-navigation-help-visible {
    display: block
}

.cesium-touch-navigation-help-visible {
    display: block
}

.cesium-navigation-help-pan {
    color: #6cf;
    font-weight: 700
}

.cesium-navigation-help-zoom {
    color: #65fd00;
    font-weight: 700
}

.cesium-navigation-help-rotate {
    color: #ffd800;
    font-weight: 700
}

.cesium-navigation-help-tilt {
    color: #d800d8;
    font-weight: 700
}

.cesium-navigation-help-details {
    color: #fff
}

.cesium-navigation-button {
    color: #fff;
    background-color: transparent;
    border-bottom: none;
    border-top: 1px solid #444;
    border-right: 1px solid #444;
    margin: 0;
    width: 50%;
    cursor: pointer
}

.cesium-navigation-button-icon {
    vertical-align: middle;
    padding: 5px 1px
}

.cesium-navigation-button:focus {
    outline: 0
}

.cesium-navigation-button-left {
    border-radius: 10px 0 0 0;
    border-left: 1px solid #444
}

.cesium-navigation-button-right {
    border-radius: 0 10px 0 0;
    border-left: none
}

.cesium-navigation-button-selected {
    background-color: rgba(38, 38, 38, .75)
}

.cesium-navigation-button-unselected {
    background-color: rgba(0, 0, 0, .75)
}

.cesium-navigation-button-unselected:hover {
    background-color: rgba(76, 76, 76, .75)
}

.cesium-selection-wrapper {
    position: absolute;
    width: 160px;
    height: 160px;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .2s, opacity .2s ease-in
}

.cesium-selection-wrapper-visible {
    visibility: visible;
    opacity: 1;
    transition: opacity .2s ease-out
}

.cesium-selection-wrapper svg {
    fill: #2e2;
    stroke: #000;
    stroke-width: 1.1px
}

.cesium-timeline-main {
    position: relative;
    left: 0;
    bottom: 0;
    overflow: hidden;
    border: solid 1px #888
}

.cesium-timeline-trackContainer {
    width: 100%;
    overflow: auto;
    border-top: solid 1px #888;
    position: relative;
    top: 0;
    left: 0
}

.cesium-timeline-tracks {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}

.cesium-timeline-needle {
    position: absolute;
    left: 0;
    top: 1.7em;
    bottom: 0;
    width: 1px;
    background: red
}

.cesium-timeline-bar {
    position: relative;
    left: 0;
    top: 0;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 1.7em;
    background: linear-gradient(to bottom, rgba(116, 117, 119, .8) 0, rgba(58, 68, 82, .8) 11%, rgba(46, 50, 56, .8) 46%, rgba(53, 53, 53, .8) 81%, rgba(53, 53, 53, .8) 100%)
}

.cesium-timeline-ruler {
    visibility: hidden;
    white-space: nowrap;
    font-size: 80%;
    z-index: -200
}

.cesium-timeline-highlight {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #08f
}

.cesium-timeline-ticLabel {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    font-size: 80%;
    color: #eee
}

.cesium-timeline-ticMain {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 50%;
    background: #eee
}

.cesium-timeline-ticSub {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 33%;
    background: #aaa
}

.cesium-timeline-ticTiny {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 25%;
    background: #888
}

.cesium-timeline-icon16 {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background-image: url('../assets/cesium/TimelineIcons.png');
    background-repeat: no-repeat
}

.cesium-viewer {
    font-family: sans-serif;
    font-size: 16px;
    overflow: hidden;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.cesium-viewer-cesiumWidgetContainer {
    width: 100%;
    height: 100%
}

.cesium-viewer-bottom {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-right: 5px
}

.cesium-viewer .cesium-widget-credits {
    visibility: hidden; /* Added because our offline implementation does not use Cesium Ion and so the credit is not needed */
    display: inline;
    position: static;
    bottom: auto;
    left: auto;
    padding-right: 0;
    color: #fff;
    font-size: 10px;
    text-shadow: 0 0 2px #000
}

.cesium-viewer-timelineContainer {
    position: absolute;
    bottom: 0;
    left: 169px;
    right: 29px;
    height: 27px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-size: 14px
}

.cesium-viewer-animationContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
    width: 169px;
    height: 112px
}

.cesium-viewer-fullscreenContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    width: 29px;
    height: 29px;
    overflow: hidden
}

.cesium-viewer-vrContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    width: 29px;
    height: 29px;
    overflow: hidden
}

.cesium-viewer-toolbar {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px
}

.cesium-viewer-cesiumInspectorContainer {
    display: block;
    position: absolute;
    top: 50px;
    right: 10px
}

.cesium-viewer-geocoderContainer {
    position: relative;
    display: inline-block;
    margin: 0 3px
}

.cesium-viewer-cesium3DTilesInspectorContainer {
    display: block;
    position: absolute;
    top: 50px;
    right: 10px;
    max-height: calc(100% - 120px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden
}