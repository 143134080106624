.modal-background{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: space-around;

    animation-name: modal-background-fade-in;
    animation-duration: 750ms;
    animation-fill-mode: forwards;

    z-index: 100000000;
}

@keyframes modal-background-fade-in{
    from{
        background-color: rgba(0, 0, 0, 0.0);
        backdrop-filter: blur(0);
    }
    to{
        background-color: rgba(0, 0, 0, 0.33);
        backdrop-filter: blur(2px);
    }
}

.modal-body-wrapper{
    padding: 0.5rem;
    background-color: whitesmoke;
    border-radius: 3px;
    box-shadow: 5px 5px 20px grey;

    
    animation-name: modal-transition;
    animation-duration: 750ms;
    animation-fill-mode: forwards;
}

@keyframes modal-transition{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.modal-content-wrapper{
    padding: 0.5rem;
    max-width: 85vw;
    max-height: 85vh;
    overflow-y: auto;
}

.modal-close-button{
    width: 24px;
    text-align: left;
    
    position: relative;
    top: -0.5rem;
    
    cursor: pointer;
    padding-top: 0.5rem;

}