

.hint-modal-wrapper{
    /* border: 1px solid black; */
}

/* .hint-modal-content-container-animated {
    animation-name: zoom-in;
    animation-duration: 100ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
} */

@keyframes fade-in{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.hint-modal-content-container{
    padding: 0.25rem 0.5rem;
    /* position: absolute; */

    box-shadow: 2px 2px 5px grey;

    z-index: 1000000000;

    background-color: white;
}





.hint-modal-close-button{
    width: 24px;
    text-align: left;
    
    position: relative;
    /* top: 2rem; */
    
    cursor: pointer;

}