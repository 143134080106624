.explorer-grid-area {
    width: calc(100% - 1px);
    height: calc(100%);
}


/* SIDEBAR */

.explorer-sidebar-open {
    display: block;
    width: 100%;
    min-width: 200px;
    text-align: left;
}

.explorer-sidebar-closed {
    display: none;
}

.sidebar-section {
    background-image: linear-gradient(to bottom right, var(--neutral-1), var(--neutral1));
    width: 95%;
    margin: 0.25rem auto;
    padding: 0.25rem;
    box-sizing: border-box;
    box-shadow: 2px 2px 3px var(--neutral-3) inset;
    color: var(--neutral10);

    overflow-y: auto;
}

.sidebar-subsection{

    background-color: var(--neutral3);
    margin: 0.15rem 0 0;

    padding: 0.25rem;

    box-sizing: border-box;

}


.sidebar-layer-title {
    display: inline-block;
    max-width: 90%;
    margin: 0 0 0 0.25rem;
    font-weight: bold;
    cursor: pointer;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.layer-checkbox{
    cursor: pointer;
}

.sidebar-layer-title.selected-true{
    color: var(--secondaryAccent1);
}

/* RIBBON */

#explorer-ribbon {
    /* background-color: red; */
    text-align: left;
}

.ribbon-section{
    width: 100%;
    margin-top: 0.5rem;
    color: var(--neutral10);
}


.ribbon-subsection{

    background-image: linear-gradient(to bottom right, var(--neutral1), var(--neutral3));
    margin: 0.15rem 0 0;

    padding: 0.25rem;

    box-sizing: border-box;
    box-shadow: 2px 2px 3px var(--neutral-3) inset;
}

.ribbon-section input[type="text"]{
    border: none;
    font-size: 1.1rem;
    margin: 0.25rem 0.25rem 0.25rem 0;
    padding: 0.1rem;

    box-shadow: 2px 2px 10px var(--neutral0);

    background-color: var(--neutral0);
    color: var(--neutral10);
}


.sidebar-section-textarea, .ribbon-section-textarea{
    width: 93%;
    display: block;
    resize: none;
    height: 50%;

    margin: 0.25rem auto 0;
    padding: 0.5rem;

    color: var(--neutral10);
    background-color: var(--neutral-1);

    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 5px var(--neutral-3);
}

.ribbon-subsection-text{
    margin: 0;
}
#default-lat, #default-lng, #default-alt {
    width: 9rem;
}

/* IMAGE PREVIEW */

.layer-image-preview{
    overflow-x: auto;
    width: 100%;

    margin: 1rem auto;
}

.thumbnail-mask{
    display: inline-block;
    margin: 0.25rem;
    width: fit-content;
    height: fit-content;
    
    position: relative;

    box-shadow: 1px 1px 5px var(--neutral-3);
    background-image: url('../../assets/icons/satellite.svg');
    background-size: contain;
    
}

.thumbnail-interface-text{
    margin: 0;
}


.thumbnail-preview{
    cursor: pointer;
}

.thumbnail-button-group{
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    
}

.thumbnail-question-container{
    display: inline-block;
    width: fit-content;
    height: fit-content;
    max-width: 128px;

    margin: 0;
    padding: 0;

    border: none;

    color: var(--neutral0);
    z-index: 100000;
}


/* SHARING */

#shared-selected-form-layers{
    width: 100%;
    max-height: 5rem;
}

.sharing-link-textarea{
    width: 95%;
    min-height: 5rem;
    margin: 0 auto;

    resize: none;
}



/* FEATURE INFO BOX */
#feature-info-box{
    width:100%;
    height: 100%;
}

.feature-info-box-select{
    max-width: 15rem;
}

.feature-info-selection-index {
    display: inline-block;
    margin: 0 0.1rem;
}

.feature-info-selection-index-selected-true{
    font-weight: bold;
}


/* ICONS */

.font-verdana{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
