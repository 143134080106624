.nav-arrow-container{   
    position: relative;
    cursor: pointer;
}

.nav-arrow-tail{  
    margin: 0;
    padding: 0;

    position: absolute;
}


.arrow-body{

    display: inline-block;
    position: relative;
    padding: 0 0.25rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    cursor: pointer;
    box-sizing: border-box;

}

.nav-arrow-head{
    margin: 0 1.5rem 0 0;
   
    position: absolute;
}
