.selection-table-container{
    overflow-y: scroll;
    overflow-x: hidden;
}

.selection-table{
    width: 100%;
    margin: 0.5rem auto;
    text-align: left;
    border-collapse: collapse;    
}

.selection-table th{
    border-bottom: 2px solid black;
}

.selection-table tr td {
    margin: 0.1rem 0.25rem;
    cursor: pointer;
    vertical-align: top;
}

.selection-table th{
    background-color: #ffffff00;
}