:root{
  --content-container-height: 92vh;
}


.App {
  text-align: center;

  font-weight: 300;
  letter-spacing: 1px;

  overflow: hidden;
}

button{
  cursor: pointer;
}

/* input[type="submit"], .input-btn {
  text-transform: uppercase;
  font-weight: bold;
} */

input[type="text"], input[type="number"], select {
  font-family: Jura, sans-serif;
  color: black;
  font-weight: bold;
}


.content-container{
  height: var(--content-container-height);
}


.logo{
  margin: 0 auto;
}

.logo-sm{
  height: 100px;
  width: 100px;
}

.logo-md{
  height: 250px;
  width: 250px;
}

/* GENERAL */
.form-error-message{
  margin: 0;
  padding: 0.25rem;
  color: var(--neutral0);
  font-weight: bolder;

  background-color: var(--secondaryAccent0);
}

.form-error-message::before{
  content: "Error: "
}

/* DZYNE COMPONENTS STYLE OVERRIDES */

.tab-group-container{
  text-transform: capitalize;
}

.drawer-container{
  max-width: 100%;
}

.nav-height {
  height: calc(8vh - 0.5rem);
}

/* undo style overides by tailwind */
.map-popup-window-controls-3d > .map-popup-close-btn-3d {
  left: 0;
  right: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;

}
.map-popup-window-controls-3d {
  min-height: 1.5rem !important;
}