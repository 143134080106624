/* styles that allow tailwind 3 features in a tailwind 2 project*/
/* should be imported by projects that use tailwind 2 */

.w-fit {
	width: fit-content;
}

.shadow-black {
	box-shadow: 2px 2px 5px black
}

.backdrop-blur-sm {
	backdrop-filter: blur(8px);
}

.backdrop-blur {
    backdrop-filter: blur(8px);
}