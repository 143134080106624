.action-button{
    font-size: 2rem;
    display: inline-block;
    padding: 1rem 4rem;
    width: 100%;

    border-radius: 4px;

    cursor: pointer;

    white-space: nowrap;
}

.action-button:disabled{
    /* color: var(--textColor5) !important;
    background-color: var(--textColor6) !important;
    border: 4px solid var(--textColor5) !important; */
    opacity: 0.5 !important;
    cursor: auto !important;
}




.action-button-emphasis-true{
    animation-name: pulse-action-button;
    animation-duration: 1000ms;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

@keyframes pulse-action-button{
    0%{
        opacity: 0.5;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0.5;
    }
}