.form-completion input[type="submit"],
.form-completion button
{
    text-transform: capitalize;
    font-size: 1.15rem;
    
    padding: 0.2rem 0.5rem;
    margin: 0.5rem 0.5rem;
    
    border-radius: 2px;
    
    border: none;
    
    box-shadow: 2px 2px 5px grey;
    
    cursor: pointer;
    
}

.form-completion input[type="submit"]:hover,
.form-completion button:hover
{
    animation-name: hover-form-completion;
    animation-duration: 333ms;
    animation-fill-mode: forwards;
}

@keyframes hover-form-completion{
    from{
        box-shadow: 2px 2px 5px grey;        
    }
    to{
        box-shadow: 3px 3px 10px grey;        
    }
}