@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .btn {
    @apply p-2 font-bold uppercase rounded m-2 cursor-pointer
  }
}

.btn:hover{
  animation-name: btn-hover;
  animation-duration: 333ms;
  animation-fill-mode: forwards;
}
@keyframes btn-hover{
  from{
    box-shadow: 0 0 0 grey;
  }
  to{
    box-shadow: 2px 2px 5px grey;
  }
}

@font-face {
  font-family: 'Jura';
  src: local('Jura'), url(./fonts/Jura/static/Jura-Regular.ttf);
}
@font-face {
  font-family: 'Jura';
  src: local('Jura'), url(./fonts/Jura/static/Jura-Bold.ttf);
  font-weight: bold;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} 
